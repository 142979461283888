$panel-background: #16161a;
$header-color: #fffffe;
$text-color: #94a1b2;
$text-color-white: #fffffe;

$break-small: 750px;
$break-large: 1200px;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #7f5af0;
}

.app-loader {
  background-color: $panel-background;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fffffe;
}

.map {
  height: 100vh;

  @media screen and (max-width: $break-small), (max-height: $break-small) {
    height: 80vh;
  }
}

.map-container {
  position: relative;
}

.panel {
  position: absolute;
  padding: 1rem 2rem;
  background-color: $panel-background;
  border-radius: 10px;
  z-index: 999;
  color: $text-color;
  max-height: 75vh;
  overflow-y: auto;

  &.legend {
    right: 3vw;
    bottom: 2rem;
  }

  &.slider {
    bottom: 2rem;
    left: 5vw;
    width: 75vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: $break-small), (max-height: $break-small) {
      position: relative;
      padding-top: 1rem;
      width: 90vw;
      left: 0;
      bottom: 0;
      border-radius: 0;
    }
  }

  &.main {
    left: 2rem;
    top: 2rem;
    width: 22rem;

    @media screen and (max-width: $break-small), (max-height: $break-small) {
      position: relative;
      padding-bottom: 1rem;
      width: 90vw;
      left: 0;
      top: 0;
      border-radius: 0;
    }
  }

  &.selected-county {
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 18rem;

    @media screen and (max-width: $break-small), (max-height: $break-small) {
      width: 20vw;
      right: 0;
      top: 0;
      border-radius: 0;
      height: 80vh;
    }
  }
}

.slider-date {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: $header-color;
}

h1 {
  color: $header-color;
  font-size: 1.6rem;
  line-height: 1em;
}

h2 {
  color: $header-color;
  font-size: 1.4rem;
  line-height: 1em;
}

.MuiFormLabel-root {
  color: $text-color !important;
}

.radio-control {
  color: red;

  &.Mui-checked {
    color: green;
  }
}

.MuiIconButton-label {
  color: $text-color;
}

.selected-county {
  h2 {
    margin-top: 0.6rem;
    margin-bottom: 0.2rem;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.selected-county-stat {
  margin-bottom: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .label.b {
  }

  .value.b {
  }
}

.timeline-button {
  margin-top: 0.6rem;
  margin-bottom: 0.4rem;
}

button {
  background: #7f5af0;
  color: $text-color-white;
  border: none;
  border-radius: 6px;
  padding: 1rem 1.5rem;
  font-size: 14px;

  &:hover {
    background: #6648c0;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background: #6648c0;

    &:hover {
      background: #7f5af0;
    }
  }
}

.timeline-jump-button {
  color: #7f5af0;
  margin-top: 0.5rem;

  &:hover {
    color: #6648c0;
    cursor: pointer;
  }
}

.timeline-container {
  overflow: overlay;
  overflow-x: auto;
  max-height: 42vh;
  margin-top: 0.6rem;
}

.timeline-container {
  overflow-x: hidden;
  overflow-y: scroll; // manage scrollbar content overflow settings
}
.timeline-container::-webkit-scrollbar {
  width: 8px; // manage scrollbar width here
}
.timeline-container::-webkit-scrollbar * {
  background: transparent; // manage scrollbar background color here
}
.timeline-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(
    #7f5af0,
    0.6
  ) !important; // manage scrollbar thumb background color here
}

.legend {
  h2 {
    margin: 0;
    margin-bottom: 1rem;
  }
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;

    .legend-color {
      width: 1rem;
      height: 1rem;
    }

    .legend-text {
      padding-left: 1rem;
    }
  }
}

// Spinner Css
